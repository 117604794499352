<script lang="ts">
	type Props = {
		title: string
		description: string
		author?: string
		index?: 'index' | 'noindex'
		follow?: 'follow' | 'nofollow'
	}
	let {
		title,
		description,
		author = 'Word Automator',
		index = 'index',
		follow = 'follow',
	}: Props = $props()
</script>

<svelte:head>
	<title>{title}</title>
	<meta name="description" content={description} />
	<meta name="author" content={author} />
	<meta name="robots" content={`${index}, ${follow}`} />
</svelte:head>
